import React, { useState } from 'react';
import "./Podcast.scss";
import { Container, Col, Row } from 'react-bootstrap';
import IFRAME from '../IFRAME/IFRAME';

const Podcast = (): JSX.Element => {

    return (
        <section className="podcast-container podcast">
            <Container className="content-container">
                <Row>
                    <Col>
                        <div >
                            <h1>Podcast</h1>
                            <span className="podcast-txt">Check out my podcasts on various software topics!</span>
                        </div>
                    </Col>
                </Row>
                <Row className="podcast-container">
                    <Col md={6}>
                        {/* <IFRAME src="https://www.youtube.com/embed/XNURMfdthYE" style={{ minHeight: '200px' }} /> */}
                        <IFRAME src="https://www.youtube.com/embed/XNURMfdthYE" className="iframe" />
                    </Col>
                    <Col md={6}>
                        <IFRAME src="https://www.youtube.com/embed/Bo2q5EwycEk" className="iframe" />
                        {/* <iframe src="https://www.youtube.com/embed/Bo2q5EwycEk" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                    </Col>
                    <Col md={6}>
                        <IFRAME src="https://www.youtube.com/embed/ULU5tck-mhc" className="iframe" />
                        {/* <iframe src="https://www.youtube.com/embed/ULU5tck-mhc" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                    </Col>
                    <Col md={6}>
                        <IFRAME src="https://www.youtube.com/embed/65I-0xEUONI" className="iframe" />
                        {/* <iframe src="https://www.youtube.com/embed/65I-0xEUONI" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                    </Col>
                </Row>

            </Container>

        </section>
    )
}

export default Podcast;