import "./Sections.scss";

import React, { useState } from 'react'
import { Container, Col, Row } from "react-bootstrap";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    withRouter
} from "react-router-dom";
import Youtube from "../Youtube/Youtube";
import Mission from "../Mission/Mission";
import Contact from "../Contact/Contact";
import About from '../AboutMe/About';
import Podcast from '../Podcast/Podcast';
import {
    TransitionGroup,
    CSSTransition
} from 'react-transition-group';
// const LoadableAbout = Loadable({
//     loader: () => import("../AboutMe/About"),
//     loading() {
//         return Loading;
//     }
// });

// const LoadablePodcast = Loadable({
//     loader: () => import("../Podcast/Podcast"),
//     loading() {
//         return <div>LOADING</div>;
//     }
// })

// function Loading(props) {
//     if (props.error) {
//         return <div>Error! <button onClick={props.retry}>Retry</button></div>;
//     } else if (props.pastDelay) {
//         return <div>Loading...</div>;
//     } else {
//         return null;
//     }
// }

function Sections(): JSX.Element {

    const nodeRef = React.useRef(null);

    return (
        <Container className="main-container" fluid>
            <Row>
                <div className="section-container flex-container flex-column">
                    <Route render={({ location }) => (
                        <TransitionGroup>
                            <CSSTransition nodeRef={nodeRef} key={location.key} timeout={300} classNames='fade'>
                                <div ref={nodeRef}>
                                    <Switch location={location}>
                                        <Route exact path="/home">
                                            <div className="flex-container flex-column">
                                                <Link className="section" to="/home/youtube">Youtube Channel</Link>
                                                <Link className="section" to="/home/podcast">Podcasts</Link>
                                                <Link className="section" to="/home/mission">Mission</Link>
                                                <Link className="section" to="home/about">About</Link>
                                                {/* <Link className="section" to="/about">Resume</Link> */}
                                                {/* <Link className="section" to="/">Code With Me</Link> */}
                                                <Link className="section" to="/home/contact">Contact</Link>
                                            </div>
                                        </Route>
                                        <Route path="/home/youtube" exact component={Youtube} />
                                        <Route path="/home/podcast" component={Podcast} />
                                        <Route path="/home/about" exact component={About}>
                                        </Route>
                                        <Route path="/home/mission" exact>
                                            <Mission />
                                        </Route>
                                        <Route path="/home/contact" exact>
                                            <Contact />
                                        </Route>
                                    </Switch>
                                </div>

                            </CSSTransition>
                        </TransitionGroup>
                    )}
                    />
                </div>
            </Row>
        </Container >
    )
}

export default Sections;
