import React, { useState } from 'react';
import "./Nav.scss";
import Nav from 'react-bootstrap/Nav'
import { Navbar, NavDropdown, Form, FormControl, Button, Container } from 'react-bootstrap';
import logo from "../../assets/images/loc-logo.png";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    Link,
    BrowserRouter as Router,
    Route,
    Redirect
} from "react-router-dom";

const AppNavBar = (): JSX.Element => {
    const [redirect, updateRedirect] = useState(false);
    const homeClicked = () => {
        updateRedirect(!redirect);
    }

    return (
        <Container fluid className="navBar">
            {/* <Navbar collapseOnSelect={true} bg="dark" variant="dark" expand="lg" fixed='top'> */}
            <Navbar fixed="bottom" >
                <div className="flex-container flex-center full-height">
                    {/* <div className="tag-line">Making Coding Awesome!</div> */}

                    <Route render={({ history }) => (
                        <button
                            type='button'
                            onClick={() => { history.push('/') }}
                            className="buttonHome"
                        >
                            <FontAwesomeIcon className="homeIcon" icon={faHome}></FontAwesomeIcon>
                        </button>
                    )} />
                </div>
                {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                {/* <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto">
                        <Nav.Link href="#home">Home</Nav.Link>
                        <Nav.Link href="#about-me">About</Nav.Link>
                        <Nav.Link href="#youtube">Youtube</Nav.Link>
                    </Nav>
                </Navbar.Collapse> */}
            </Navbar >
        </Container >
        // <>
        // </>
    )
};

export default AppNavBar;