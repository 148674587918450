import React from 'react';
import mission from '../../assets/images/mission-bg.jpeg';
import './Mission.scss';

const Mission = (): JSX.Element => {

    return (
        <div className="mission-container">
            <div className="bg-overlay"></div>
            <div className="flex-conatiner flex-column mission-title">
                <h1>My Mission</h1>
                <div className="mission-txt flex-container flex-column">
                    <p>
                        In my early stages of programming at a boot camp, when I couldn't reverse a link list
                        to save my life, I found immense joy pair programming and learning with others!
                        While we tried to navigate through these hard problems I learned how fun coding can really be!
                    </p>
                    <p>
                        Nowadays, it's hard to have the same level of excitement while programming.
                        Most tutorials online are from teachers that just too robotic.
                        They lack empathy for what the average student's abilities are and assume that explaining it in a quantitative way works for everyone.
                        This causes people to stray away from coding and gives the profession in general a bad connotation
                    </p>
                    <p>
                        I won't stand for that and that's why I strive to make coding awesome!
                        I have 40+ videos on my youtube channel lesson on coding where spice up the learning process of programming!
                        I teach in an informative manner that is engaging, and won't lead you dozing off while learning.
                        I also offer personal tutorial sessions! These will be coming soon and you will be able to book through my website! Stay tuned!
                    </p>
                </div>

            </div>
            {/* <img src={mission} className="mission-img full-width" /> */}
        </div>
    )
}

export default Mission;