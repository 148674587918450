import React, { useState, IframeHTMLAttributes } from 'react';
import './IFRAME.scss';

export const IFRAME = (props: IframeHTMLAttributes<HTMLIFrameElement>) => {
    const [loading, setLoading] = useState(true);

    const placeHolder =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII='

    const loadedFrame = () => {
        setLoading(false);
    }


    return (
        <>
            {loading && (
                <iframe  {...props} style={{ background: "gray", border: "1px solid black" }} src={placeHolder} />
            )}
            <iframe  {...props} style={!loading ? { display: 'block' } : { display: 'none' }} onLoad={loadedFrame} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen ></iframe>
        </>
    )
}


export default IFRAME;
