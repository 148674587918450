import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Contact.scss";
import { SocialMediaIconsReact } from 'social-media-icons-react';

interface Props {

}
interface State {

}

export default class Contact extends Component<Props, State> {
    state = {}

    render() {
        return (
            <div className="contact-container">
                <Container className="border-container">
                    <div className="flex-container flex-center">
                        <h1>Contact</h1>
                    </div>
                    <Row className="flex-column">
                        <Col>
                            <div className="flex-container flex-center">
                                <div className="flex-container flex-center">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                                <span className="emailAddress">lessonryan@gmail.com</span>
                            </div>
                        </Col>
                        <Col>
                            <div className="socialLinkContainer">
                                {/* <FontAwesomeIcon icon={faInstagram} />
                                <FontAwesomeIcon icon={faGithub} />
                                <FontAwesomeIcon icon={faYoutube} /> */}
                                <h3>Social Links</h3>
                                <span className="socialLinks">
                                    <SocialMediaIconsReact icon="linkedin" iconSize="5" roundness="100%" url="https://www.linkedin.com/in/ryanlesson/" size="50" />
                                </span>
                                <span className="socialLinks">
                                    <SocialMediaIconsReact icon="instagram" iconSize="5" roundness="100%" url="https://https://www.instagram.com/lessononcoding/?hl=en" size="50" />
                                </span>
                                <span className="socialLinks">
                                    <SocialMediaIconsReact icon="github" iconSize="5" roundness="100%" url="https://https://github.com/Bigless27" size="50" />
                                </span>
                                <span className="socialLinks">
                                    <SocialMediaIconsReact icon="youtube" iconSize="5" roundness="100%" url="https://youtube.com/lessononcoding" size="50" />
                                </span>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h3>Contact Form</h3>
                            <span>Comming soon!!!</span>
                        </Col>
                    </Row>
                </Container>
            </div>

        )
    }
}

