import React, { useState, useRef } from 'react';
// import logo from './logo.svg';
import './App.scss';
import HomeComponent from "./components/Home";
import AppNavBar from "./components/Nav/Nav";
import Footer from "./components/Footer/Footer";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect
} from "react-router-dom";
import mainMobileNoTxt from "./assets/images/main-img-notxt.png";
import loclogo from "./assets/images/loc-logo.png";
// import { Button } from 'react-bootstrap';


/* The following line can be included in your src/index.js or App.js file */

import './App.scss';
import CodeWithMe from './components/CodeWithMe/CodeWithMe';
import Sections from './components/Sections/Sections';

function App(): JSX.Element {
  const [loading, setLoading] = useState(true);
  // function handelOnChange(this: any, e: FormEvent<HTMLButtonElement>): void {
  //   e.preventDefault()

  // }

  const imgLoaded = () => {
    setLoading(false);
    let loadingScreen = document.getElementById("main-loading");
    if (loadingScreen) loadingScreen.style.display = "none";
  }




  return (
    <div className={loading ? "hide" : "fade-in"}>
      <Router>
        <AppNavBar />
        <div className="App">
          <div className="posRel main-container">
            <div className="posRel">
              <img className="main-mobile-img full-width" src={mainMobileNoTxt} />
              <img ref={(input: HTMLImageElement) => {
                // onLoad replacement for SSR
                if (!input) { return; }
                const img = input;

                const updateFunc = () => {
                  imgLoaded();
                };
                img.onload = updateFunc;
                if (img.complete) {
                  updateFunc();
                  img.onload = null;
                }
              }} src={loclogo} className="locLogo" />
              <div className="full-div"></div>
            </div>
            <Switch>
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
              <Route path="/home">
                <Sections />
              </Route>
              <Route>
                <Redirect to="/home" />
              </Route>
            </Switch>
            {/* <img src={logo} className="centerLogo" /> */}
          </div>
          {/* <CodeWithMe /> */}
          <Footer />
        </div>
      </Router>
    </div>

  );
}

export default App;
