import React from "react";
import "./Footer.scss";
import { SocialMediaIconsReact } from 'social-media-icons-react';

const Footer = (): JSX.Element => {
    return (
        <>
            <div className="footer">
                <span className="like">Like & Subscribe</span>
                <SocialMediaIconsReact borderStyle="solid" icon="youtube-play" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(255,1,1,1)" iconSize="5" roundness="30%" url="https://youtube.com/lessononcoding" size="63" />
            </div>
        </>
    )
}

export default Footer;