import Container from "react-bootstrap/Container";
import React, { MouseEvent } from "react";
import { Row, Col, Button } from "react-bootstrap";
import youtubetitle from "../../assets/images/youtube.png";
import "./Youtube.scss";
import { SocialMediaIconsReact } from 'social-media-icons-react';
import IFRAME from '../IFRAME/IFRAME';

const Youtube = (): JSX.Element => {
    const goToYoutube = () => {
        const url = "https://youtube.com/lessononcoding";
        window.open(url, '_blank');
    }

    return (
        <div className="youtube-container">
            <Container fluid className="content-container">
                <Row>
                    <Col id="youtube" className="flex-container flex-center flex-column">
                        {/* <img className="title-txt youtube" src={youtubetitle}></img> */}
                        <h1>Youtube Channel</h1>
                        {/* <Button variant="primary" className="youtubeButton" onClick={goToYoutube}>GO</Button>
                         */}
                        <span className="play-btn">
                            <SocialMediaIconsReact icon="youtube-play" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(255,1,1,1)" iconSize="5" roundness="10%" url="https:youtube.com/lessononcoding" size="81" />
                        </span>
                    </Col>
                    <Col md={12}>
                        <div>
                            Be sure to check out my youtube channel!
                            It's chalk full of tutorials and all things software!
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="youtube-space" md={6}>
                        {/* width=560 height=315 */}
                        <IFRAME src="https://www.youtube.com/embed/5tm_rR-zBec" className="iframe" />
                        {/* <iframe
                            src="https://www.youtube.com/embed/5tm_rR-zBec" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                    </Col>
                    <Col className="youtube-space" md={6}>
                        <IFRAME src="https://www.youtube.com/embed/vXO5JMiKtM8" className="iframe" />
                        {/* <iframe
                            src="https://www.youtube.com/embed/vXO5JMiKtM8" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                    </Col>
                    <Col className="youtube-space" md={6}>
                        <IFRAME src="https://www.youtube.com/embed/AX6ECLcJzps" className="iframe" />
                        {/* <iframe
                            src="https://www.youtube.com/embed/AX6ECLcJzps" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                    </Col>
                    <Col className="youtube-space" md={6}>
                        <IFRAME src="https://www.youtube.com/embed/wYc4JJeTheI" className="iframe" />
                        {/* <iframe
                            src="https://www.youtube.com/embed/wYc4JJeTheI" frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe> */}
                    </Col>
                </Row>
            </Container>
        </div >
    )
}

export default Youtube