
import React, { useState, SyntheticEvent } from 'react';
import "./About.scss";
import { Container, Col, Row } from 'react-bootstrap';
import profilePic from "../../assets/images/profile-pic-no-bg.png";


export default function About(): JSX.Element {
    const [imgLoaded, setImgLoaded] = useState(true);

    const placeHolder =
        'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII='


    const loadedImg = () => {
        setImgLoaded(false);
    }


    return (
        <div>
            <div className="base-background">
                <Container fluid className="about-container">
                    <Row>
                        <Col md={12}>
                            <Row >
                                <Col id="about-me" className="flex-container flex-center "  >
                                    {/* <img className="title-txt" src={aboutme} /> */}
                                    <h1>About Me</h1>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="flex-container flex-center" md={6}>
                                    <div className="wrapper about-img full-width">
                                        <img src={placeHolder} className={"background-img-responsive full-width " + (imgLoaded ? '' : 'hide')} />
                                        <img onLoad={loadedImg} src={profilePic} className={"background-img-responsive full-width " + (imgLoaded ? 'hide' : '')} />
                                    </div>
                                </Col>
                                <Col className="about-description" md={6}>
                                    <p>
                                        Hey everyone welcome to lessononcoding.com! My name is Ryan Lesson.
                                        I'm a coding wizard master with a power level much over 9000!
                                        I work full time primarily in consulting and my coding strengths lie within Javascript and the frontend.
                                        But, I do work full stack with .Net core and Node!
                                </p>
                                    <p>
                                        This is where I post all my latest updates on what projects I'm buiding and what youtube videos are coming soon.
                                        If you want to connect feel free to reach out!
                                </p>

                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
